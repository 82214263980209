<template>
    <div class="container">
        <footer>
        <p class="bottom">
            2023 @ GROKBOY Finance, an anon community, brand, and movement
        </p>
    </footer>
    </div>
</template>

<style lang="less" scoped>
footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #76c454;
    // border-top: 1px solid #79234b;
    font-size: 14px;
    padding: 20px 0;
    p {
        opacity: .59;
        color: rgba(0, 0, 0, 1);
        font-family: 'Inter';
    }
}

</style>